import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss']
})
export class ToolbarLogoComponent {
	@Input() showCross = false;
	@Input() order;
	@Output() close = new EventEmitter<void>();
	@Output() openchangeTable = new EventEmitter<void>();
	environment = environment;
	pt = PreorderType;
	constructor(
		private router: Router,
		public repository: RepositoryService,
		private modalCtrl: ModalController
	) {}
	home() {
		HomePage.navigate(this.router, this.repository);
	}
	closeWindow() {
		this.close.emit();
		this.modalCtrl.dismiss();
	}
	changeTable() {
		this.openchangeTable.emit();
	}
}
