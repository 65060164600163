import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router, ActivatedRoute} from '@angular/router';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {MapPage} from '../map/map.page';
import {TranslateService} from '@ngx-translate/core';
import {MenuPage} from '../menu/menu.page';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {SelectVenuePage} from '../select-venue/select-venue.page';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.sushisupply.html',
	styleUrls: ['./home.page.sushisupply.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/:venueId/:tableNumber';

	environment = environment;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	languageEnum = Language;

	loading = false;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		private snackbarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
		this.route.paramMap.subscribe(params => {
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(
					params.get('tableNumber'),
					params.get('venueId')
				);
			}
		});
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
		// this.repository.previousPreorder.emit(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.emit(null);
		this.repository.payment.emit(null);
	}
	async locateAndShowStores() {
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		this.repository.address.emit(null);
		await SelectVenuePage.navigate(this.router);
		this.loading = false;
		this.cdr.detectChanges();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading = true;
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			if (venueData && table) {
				this.repository.venue.emit(venueData);
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				await MenuPage.navigate(this.router);
			}
			this.loading = false;
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('home_page.scan_table_error'),
				null,
				{
					duration: 2000
				}
			);
			this.loading = false;
		}
	}
}
